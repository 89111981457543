/**
 * File generated by js-routes 2.2.6
 * Based on Rails 6.1.7.8 routes of SCGate::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (true &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_groups/:group_type(.:format)
 * @param {any} group_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_all_my_group_path = __jsr.r({"group_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"group_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/add(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:attack_list_id/attack_list_shop_brands/:id/add(.:format)
 * @param {any} attack_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_mypage_attack_list_attack_list_shop_brand_path = __jsr.r({"attack_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"attack_list_id"],[2,[7,"/"],[2,[6,"attack_list_shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent_close_shop_brand(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_close_shop_brand_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent_close_shop_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent_detail_close_shop_brand(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_detail_close_shop_brand_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent_detail_close_shop_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent_detail_open_shop_brand(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_detail_open_shop_brand_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent_detail_open_shop_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_detail_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent_open_shop_brand(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_open_shop_brand_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent_open_shop_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/adjacent(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjacent_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjacent"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:advertise_id/contacts(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advertise_contacts_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/advertises(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advertises_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[6,"advertises"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shop_brands/all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"all"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:id/apply(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apply_manage_sc_reserve_developer_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:id/apply(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apply_manage_sc_reserve_pm_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_population_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_population_all_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_population_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_population(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_population_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_population"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_possible_parking_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_possible_parking_all_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_possible_parking_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_possible_parking(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_possible_parking_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_possible_parking"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_shop_assort_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_shop_assort_all_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_shop_assort_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_shop_assort(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_shop_assort_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_shop_assort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_shop_count_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_shop_count_all_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_shop_count_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_shop_count(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_shop_count_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_shop_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_store_space_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_store_space_all_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_store_space_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/approximation_store_space(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approximation_store_space_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approximation_store_space"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /attached_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attached_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attached_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:advertise_id/contacts/back(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_advertise_contacts_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_manage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertise_banners/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_mypage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_mypage_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/brands_by_floor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brands_by_floor_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"brands_by_floor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/brands_by_industry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brands_by_industry_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"brands_by_industry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:id/build(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_mypage_attack_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /campaign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaign"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cities_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"cities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/clear_detail(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_detail_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"clear_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:id/click_advertise(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const click_advertise_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"click_advertise"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /compare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /compare/both_brand_list/:compare_condition_key(.:format)
 * @param {any} compare_condition_key
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_both_brand_list_path = __jsr.r({"compare_condition_key":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"both_brand_list"],[2,[7,"/"],[2,[3,"compare_condition_key"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /compare/brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/brands_compare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_brands_compare_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"brands_compare"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/build_brands_compare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_build_brands_compare_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"build_brands_compare"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/build_open_sc_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_build_open_sc_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"build_open_sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/create_compare_condition_for_build(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_create_compare_condition_for_build_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"create_compare_condition_for_build"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/download_brands_compare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_download_brands_compare_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"download_brands_compare"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/download_open_sc_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_download_open_sc_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"download_open_sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/open_sc_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_open_sc_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"open_sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/open_sc_list_compare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_open_sc_list_compare_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"open_sc_list_compare"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /compare/scs_by_graph(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_scs_by_graph_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"scs_by_graph"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:advertise_id/contacts/confirm(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_advertise_contacts_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/confirm_finish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_finish_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_finish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_manage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertise_banners/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_mypage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_mypage_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperation_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperation_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperation_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperation_users_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperation_users_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/pms/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_cooperation_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /developers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const developers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"developers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manage/users/:id/directory_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const directory_logs_manage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"directory_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_manage_cooperation_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/contacts/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_manage_contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/impressions/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_manage_impressions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"impressions"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_mypage_attack_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_cooperation_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/edit_enable_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_enable_list_manage_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"edit_enable_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit_floors(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_floors_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_floors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertises/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_advertise_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/ages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_age_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"ages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_billing_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/developer/edit(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_company_developer_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"developer"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/holdings/:id/edit(.:format)
 * @param {any} company_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_company_holding_path = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"holdings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/pm/edit(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_company_pm_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"pm"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/shop_company/edit(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_company_shop_company_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"shop_company"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/consumption_taxes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_consumption_tax_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"consumption_taxes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/contract_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_contract_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contract_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_apps/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_cooperation_app_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_apps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_cooperation_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/directories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_directory_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/floors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_floor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"floors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/genders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_gender_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"genders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_industry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/food_courts/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_food_court_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"food_courts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/populations/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_population_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"populations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_reserve_developer_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_reserve_pm_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_renewals/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_sc_renewal_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_renewals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/update_stop_scs/:id/edit(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_sc_update_stop_sc_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"update_stop_scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit_map(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_map_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_memo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mypage_attack_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:attack_list_id/attack_list_shop_brands/:id/edit(.:format)
 * @param {any} attack_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mypage_attack_list_attack_list_shop_brand_path = __jsr.r({"attack_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"attack_list_id"],[2,[7,"/"],[2,[6,"attack_list_shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/billing_address/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mypage_billing_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"billing_address"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mypage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/applications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit_replace_floor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_replace_floor_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_replace_floor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit_sales_history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sales_history_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_sales_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets/:id/edit_scs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_scs_manage_sc_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_scs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/edit_shop_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_shop_list_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_shop_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:id/enable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_manage_cooperation_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /estimate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const estimate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"estimate"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /faq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /scs/:id/floor/:floor_id(.:format)
 * @param {any} id
 * @param {any} floor_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const floor_sc_path = __jsr.r({"id":{"r":true},"floor_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"floor"],[2,[7,"/"],[2,[3,"floor_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/food_court(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const food_court_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"food_court"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /floors/get_floor_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_floor_options_floors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"floors"],[2,[7,"/"],[2,[6,"get_floor_options"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /industries/get_industry_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_industry_list_industries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[6,"get_industry_list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /industries/get_industry_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_industry_options_industries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[6,"get_industry_options"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphql_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /howtouse(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const howtouse_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"howtouse"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /scs/:id/industry/:industry_name(.:format)
 * @param {any} id
 * @param {any} industry_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const industry_sc_path = __jsr.r({"id":{"r":true},"industry_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"industry"],[2,[7,"/"],[2,[3,"industry_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/initial_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const initial_select_shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[6,"initial_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /inquiries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inquiries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inquiries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manage/advertises/:id/judgment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const judgment_manage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"judgment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses/:id/judgment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const judgment_manage_billing_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"judgment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /industries/large(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const large_industries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[6,"large"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/makepla(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const makepla_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"makepla"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/makepla(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const makepla_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"makepla"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertises/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertises/:advertise_id/advertise_banners(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_advertise_advertise_banners_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"advertise_banners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_advertise_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertises(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/ages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_age_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"ages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/ages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_ages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"ages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_billing_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_billing_addresses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/directory_logs/build(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_build_directory_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directory_logs"],[2,[7,"/"],[2,[6,"build"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/developer(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_developer_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"developer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/holdings/:id(.:format)
 * @param {any} company_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_holding_path = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"holdings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/holdings(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_holdings_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/integrations/:id(.:format)
 * @param {any} company_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_integration_path = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/pm(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_pm_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"pm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/shop_company(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_company_shop_company_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"shop_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/consumption_taxes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_consumption_tax_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"consumption_taxes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/consumption_taxes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_consumption_taxes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"consumption_taxes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/contract_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_contract_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contract_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/contract_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_contract_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contract_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_apps/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_app_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_apps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_apps/:cooperation_app_id/cooperation_app_histories(.:format)
 * @param {any} cooperation_app_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_app_cooperation_app_histories_path = __jsr.r({"cooperation_app_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_apps"],[2,[7,"/"],[2,[3,"cooperation_app_id"],[2,[7,"/"],[2,[6,"cooperation_app_histories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_apps(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_apps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_apps"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:cooperation_user_id/applications/:id(.:format)
 * @param {any} cooperation_user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_user_application_path = __jsr.r({"cooperation_user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"cooperation_user_id"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:cooperation_user_id/applications(.:format)
 * @param {any} cooperation_user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_user_applications_path = __jsr.r({"cooperation_user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"cooperation_user_id"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_cooperation_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/customers_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_customers_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"customers_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/customers_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_customers_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"customers_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/developers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_developers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"developers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/directories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_directories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/directories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_directory_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/directories/:directory_id/logs(.:format)
 * @param {any} directory_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_directory_logs_path = __jsr.r({"directory_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directories"],[2,[7,"/"],[2,[3,"directory_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/directory_logs/aggregate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_directory_logs_aggregate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directory_logs"],[2,[7,"/"],[2,[6,"aggregate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/directory_logs/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_download_directory_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directory_logs"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/floors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_floor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"floors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/floors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_floors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"floors"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/genders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_gender_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"genders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/genders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_genders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"genders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/impressions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_impressions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/ignore_suggested_keywords/:id(.:format)
 * @param {any} industry_type_industry1
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industry_ignore_suggested_keyword_path = __jsr.r({"industry_type_industry1":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"ignore_suggested_keywords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/ignore_suggested_keywords(.:format)
 * @param {any} industry_type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industry_ignore_suggested_keywords_path = __jsr.r({"industry_type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"ignore_suggested_keywords"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/suggested_keywords/:id(.:format)
 * @param {any} industry_type_industry1
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industry_suggested_keyword_path = __jsr.r({"industry_type_industry1":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"suggested_keywords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/suggested_keywords(.:format)
 * @param {any} industry_type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_industry_suggested_keywords_path = __jsr.r({"industry_type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"suggested_keywords"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/login_traces(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_login_traces_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"login_traces"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/login_traces/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_login_traces_download_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"login_traces"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/login_traces/download_force(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_login_traces_download_force_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"login_traces"],[2,[7,"/"],[2,[6,"download_force"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/login_traces/force(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_login_traces_force_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"login_traces"],[2,[7,"/"],[2,[6,"force"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/manipulation_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_manipulation_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"manipulation_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/master/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_master_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"master"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/sales_instructions/advertise/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sales_instructions_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sales_instructions"],[2,[7,"/"],[2,[6,"advertise"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sales_instructions/advertise(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sales_instructions_advertise_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sales_instructions"],[2,[7,"/"],[2,[6,"advertise"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sales_instructions/scgate/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sales_instructions_scgate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sales_instructions"],[2,[7,"/"],[2,[6,"scgate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sales_instructions/scgate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sales_instructions_scgate_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sales_instructions"],[2,[7,"/"],[2,[6,"scgate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/food_courts/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_food_court_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"food_courts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/food_courts(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_food_courts_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"food_courts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/populations/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_population_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"populations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/populations(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_populations_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"populations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_developer_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:developer_id/relations/:id(.:format)
 * @param {any} sc_id
 * @param {any} developer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_developer_relation_path = __jsr.r({"sc_id":{"r":true},"developer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"developer_id"],[2,[7,"/"],[2,[6,"relations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:developer_id/relations(.:format)
 * @param {any} sc_id
 * @param {any} developer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_developer_relations_path = __jsr.r({"sc_id":{"r":true},"developer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"developer_id"],[2,[7,"/"],[2,[6,"relations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_developers_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_pm_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:pm_id/relations/:id(.:format)
 * @param {any} sc_id
 * @param {any} pm_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_pm_relation_path = __jsr.r({"sc_id":{"r":true},"pm_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"pm_id"],[2,[7,"/"],[2,[6,"relations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:pm_id/relations(.:format)
 * @param {any} sc_id
 * @param {any} pm_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_pm_relations_path = __jsr.r({"sc_id":{"r":true},"pm_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"pm_id"],[2,[7,"/"],[2,[6,"relations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_reserve_pms_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_migrations/destination(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_sc_migrations_destination_index_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_migrations"],[2,[7,"/"],[2,[6,"destination"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_renewals/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_sc_renewal_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_renewals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_renewals(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_sc_renewals_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_renewals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_sets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/update_stop_scs/:id(.:format)
 * @param {any} sc_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_update_stop_sc_path = __jsr.r({"sc_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"update_stop_scs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/update_stop_scs(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_sc_update_stop_scs_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"update_stop_scs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands/:id/show_icon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_shop_brands_show_icon_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands/upload_icon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_shop_brands_upload_icon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"upload_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/task_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_task_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"task_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/task_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_task_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"task_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/tasks/clear_cache/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_tasks_clear_cache_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"clear_cache"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/tasks/clear_cache/execute(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_tasks_clear_cache_execute_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"clear_cache"],[2,[7,"/"],[2,[6,"execute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/tasks/copy_directories/:sc_id/confirm(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_tasks_confirm_copy_directories_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"copy_directories"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/tasks/copy_directories/:sc_id/reserve(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_tasks_copy_directories_reserve_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"copy_directories"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /industries/:type_industry1/medium(.:format)
 * @param {any} type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medium_industries_path = __jsr.r({"type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"type_industry1"],[2,[7,"/"],[2,[6,"medium"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /modal/compare/search/pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_compare_search_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /modal/compare/search/scs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_compare_search_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /modal/pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modal/shop_brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modal/stored_conditions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_stored_condition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"stored_conditions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /modal/stored_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_stored_conditions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"stored_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const multiple_destroy_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:advertise_id/advertise_banners(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_advertise_advertise_banners_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"advertise_banners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertise_banners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_advertise_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertise_banners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_advertise_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertise_banners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_attack_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:attack_list_id/attack_list_shop_brands/:id(.:format)
 * @param {any} attack_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_attack_list_attack_list_shop_brand_path = __jsr.r({"attack_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"attack_list_id"],[2,[7,"/"],[2,[6,"attack_list_shop_brands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:attack_list_id/attack_list_shop_brands(.:format)
 * @param {any} attack_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_attack_list_attack_list_shop_brands_path = __jsr.r({"attack_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"attack_list_id"],[2,[7,"/"],[2,[6,"attack_list_shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_attack_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/billing_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_billing_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"billing_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/login_count_files/:key/download(.:format)
 * @param {any} key
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_download_login_count_file_path = __jsr.r({"key":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"login_count_files"],[2,[7,"/"],[2,[3,"key"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/emergency_stop(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_emergency_stop_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"emergency_stop"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/expired(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_expired_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/favorite_pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_favorite_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"favorite_pms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/favorite_scs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_favorite_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"favorite_scs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/favorite_shop_brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_favorite_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"favorite_shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/favorite_shop_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_favorite_shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"favorite_shop_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/freeze(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_freeze_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"freeze"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/login_count_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_login_count_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"login_count_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_multiple_destroy_attack_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mypage_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize/native(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const native_oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"native"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/:advertise_id/contacts/new(.:format)
 * @param {any} advertise_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_advertise_contact_path = __jsr.r({"advertise_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"advertise_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cooperation_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cooperation_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cooperation_users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cooperation_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /inquiries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inquiry_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertises/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_advertise_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_advertise_banner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/ages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_age_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"ages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/developer/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_company_developer_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"developer"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/holdings/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_company_holding_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"holdings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/pm/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_company_pm_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"pm"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/shop_company/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_company_shop_company_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"shop_company"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/consumption_taxes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_consumption_tax_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"consumption_taxes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/contract_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_contract_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"contract_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_apps/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_cooperation_app_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_apps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_cooperation_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/cooperation_users/:cooperation_user_id/applications/new(.:format)
 * @param {any} cooperation_user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_cooperation_user_application_path = __jsr.r({"cooperation_user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"cooperation_users"],[2,[7,"/"],[2,[3,"cooperation_user_id"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/directories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_directory_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"directories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/floors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_floor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"floors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/genders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_gender_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"genders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_industry_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/ignore_suggested_keywords/new(.:format)
 * @param {any} industry_type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_industry_ignore_suggested_keyword_path = __jsr.r({"industry_type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"ignore_suggested_keywords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/industries/:industry_type_industry1/suggested_keywords/new(.:format)
 * @param {any} industry_type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_industry_suggested_keyword_path = __jsr.r({"industry_type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"industry_type_industry1"],[2,[7,"/"],[2,[6,"suggested_keywords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/food_courts/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_food_court_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"food_courts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/populations/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_population_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"populations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_reserve_developer_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/developers/:developer_id/relations/new(.:format)
 * @param {any} sc_id
 * @param {any} developer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_reserve_developer_relation_path = __jsr.r({"sc_id":{"r":true},"developer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"developers"],[2,[7,"/"],[2,[3,"developer_id"],[2,[7,"/"],[2,[6,"relations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_reserve_pm_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/reserve/pms/:pm_id/relations/new(.:format)
 * @param {any} sc_id
 * @param {any} pm_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_reserve_pm_relation_path = __jsr.r({"sc_id":{"r":true},"pm_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"reserve"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"pm_id"],[2,[7,"/"],[2,[6,"relations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_migrations/destination/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_sc_migrations_destination_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_migrations"],[2,[7,"/"],[2,[6,"destination"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_migrations/source/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_sc_migrations_source_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_migrations"],[2,[7,"/"],[2,[6,"source"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/sc_renewals/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_sc_renewal_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"sc_renewals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_set_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:sc_id/update_stop_scs/new(.:format)
 * @param {any} sc_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_sc_update_stop_sc_path = __jsr.r({"sc_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"sc_id"],[2,[7,"/"],[2,[6,"update_stop_scs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/shop_brands/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_shop_brand_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manage_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_memo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:group_type/new(.:format)
 * @param {any} group_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_my_group_path = __jsr.r({"group_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"group_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mypage_advertise_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertise_banners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mypage_advertise_banner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mypage_attack_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/attack_lists/:attack_list_id/attack_list_shop_brands/new(.:format)
 * @param {any} attack_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mypage_attack_list_attack_list_shop_brand_path = __jsr.r({"attack_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"attack_lists"],[2,[7,"/"],[2,[3,"attack_list_id"],[2,[7,"/"],[2,[6,"attack_list_shop_brands"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mypage_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/applications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_oauth_application_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manage/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_introspect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_revoke_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/occupancy_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_detail_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/occupancy_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_detail_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/occupancy_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_detail_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/occupancy_prefs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_prefs_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy_prefs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/occupancy_prefs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_prefs_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy_prefs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/occupancy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/occupancy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/occupancy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const occupancy_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"occupancy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/on_map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const on_map_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"on_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/open_pm_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_pm_list_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"open_pm_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/open_sc_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_sc_list_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"open_sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/open_sc_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_sc_list_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"open_sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/pms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pm_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/pms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pms_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[6,"pms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /postal_codes/search_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postal_codes_search_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"postal_codes"],[2,[7,"/"],[2,[6,"search_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /privacy_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ranking(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ranking/access/:model_name/:ranking_term/:display_count(.:format)
 * @param {any} model_name
 * @param {any} ranking_term
 * @param {any} display_count
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_access_path = __jsr.r({"model_name":{"r":true},"ranking_term":{"r":true},"display_count":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[2,[7,"/"],[2,[6,"access"],[2,[7,"/"],[2,[3,"model_name"],[2,[7,"/"],[2,[3,"ranking_term"],[2,[7,"/"],[2,[3,"display_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ranking/favorites/:model_name/:ranking_term/:display_count(.:format)
 * @param {any} model_name
 * @param {any} ranking_term
 * @param {any} display_count
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_favorites_path = __jsr.r({"model_name":{"r":true},"ranking_term":{"r":true},"display_count":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"model_name"],[2,[7,"/"],[2,[3,"ranking_term"],[2,[7,"/"],[2,[3,"display_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ranking/open_store(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_open_store_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[2,[7,"/"],[2,[6,"open_store"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ranking/open_store_by_industry/:type_industry1(.:format)
 * @param {any} type_industry1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_open_store_by_industry_path = __jsr.r({"type_industry1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[2,[7,"/"],[2,[6,"open_store_by_industry"],[2,[7,"/"],[2,[3,"type_industry1"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ranking/shop_brand(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ranking_shop_brand_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ranking"],[2,[7,"/"],[2,[6,"shop_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/remake(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remake_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remake"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/remove_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_all_my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/remove_items(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_items_my_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/:id/:model_type/:model_id(.:format)
 * @param {any} id
 * @param {any} model_type
 * @param {any} model_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_model_my_group_path = __jsr.r({"id":{"r":true},"model_type":{"r":true},"model_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"model_type"],[2,[7,"/"],[2,[3,"model_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/request_examination(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_examination_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_examination"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/advertises/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_advertises_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /modal/shop_brands/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_modal_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/result_on_map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_on_map_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"result_on_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/pms/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_pms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const result_shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[6,"result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /search/scs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/developer/sc_list(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_list_manage_company_developer_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"developer"],[2,[7,"/"],[2,[6,"sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/companies/:company_id/pm/sc_list(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_list_manage_company_pm_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"pm"],[2,[7,"/"],[2,[6,"sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shop_brands/:id/sc_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_list_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sc_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/sc_types(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_types_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sc_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/sc_types(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sc_types_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sc_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/scs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scs_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[6,"scs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/pms/:id/scs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scs_pm_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"scs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_conditions_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"search_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /search/scs/sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sections_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/select_sc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_sc_manage_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"select_sc"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/shop_brand_kinds_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brand_kinds_detail_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_brand_kinds_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/shop_brand_kinds(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brand_kinds_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_brand_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/shop_brands(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/shop_brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/pms/:id/shop_brands(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_pm_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"pms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/scs/:id/shop_brands(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/show_cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_brands_show_cities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[6,"show_cities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_groups/shop_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_companies_my_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_groups"],[2,[7,"/"],[2,[6,"shop_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/show_cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_companies_show_cities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[6,"show_cities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_companies/:id/shop_count_by_industry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shop_count_by_industry_shop_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shop_count_by_industry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/show_floors(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_floors_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_floors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shutten(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shutten_direct_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shutten"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shutten/pamphlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shutten_direct_pamphlet_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shutten"],[2,[7,"/"],[2,[6,"pamphlet"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shutten/sc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shutten_direct_sc_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shutten"],[2,[7,"/"],[2,[6,"sc"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shutten/shop(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shutten_direct_shop_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shutten"],[2,[7,"/"],[2,[6,"shop"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /industries/:type_industry2/small(.:format)
 * @param {any} type_industry2
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const small_industries_path = __jsr.r({"type_industry2":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"industries"],[2,[7,"/"],[2,[3,"type_industry2"],[2,[7,"/"],[2,[6,"small"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_manage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /term_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const term_of_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"term_of_service"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /term_of_zenrin_map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const term_of_zenrin_map_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"term_of_zenrin_map"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/together_shops_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const together_shops_all_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"together_shops_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /search/shop_brands/:id/together_shops(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const together_shops_shop_brand_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"shop_brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"together_shops"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_manage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/users/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_mypage_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/advertise_banners/:id/update_accept(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_accept_manage_advertise_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"advertise_banners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/update_enable_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_enable_list_manage_scs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[6,"update_enable_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/update_finish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_finish_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_finish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/update_floors(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_floors_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_floors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses/:id/update_limit_date(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_limit_date_manage_billing_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_limit_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/update_map(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_map_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/update_replace_floor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_replace_floor_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_replace_floor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mypage/advertises/:id/update_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_request_mypage_advertise_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mypage"],[2,[7,"/"],[2,[6,"advertises"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/update_sales_history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_sales_history_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_sales_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/sc_sets/:id/update_scs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_scs_manage_sc_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"sc_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_scs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/scs/:id/update_shop_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_shop_list_manage_sc_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"scs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_shop_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/update_ys_staff_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_ys_staff_password_manage_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_ys_staff_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/confirmation_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"confirmation_login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/force_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_force_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"force_login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_guide(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_guide_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_guide"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/sign_up/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_sign_up_back_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_up"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/sign_up/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_sign_up_confirm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_up"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/billing_addresses/:id/user_size_history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_size_history_manage_billing_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"billing_addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_size_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /manage/users/ys_staff_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ys_staff_password_manage_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"ys_staff_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

